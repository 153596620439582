import React from 'react'
import { Link } from 'gatsby'
import GoogleAd from './GoogleAd'

const adStyle = {
  marginBottom: '10px'
}

const Footer = () => {
  return (
    <footer>
      <GoogleAd
        client="ca-pub-4330891925037093"
        slot="1230682812"
        format="auto"
        responsive="true"
        wrapperDivStyle={adStyle}
      />
      <nav className="footer">
        <Link to='/ru/contact/'>напишите нам</Link>
      </nav>
      <div className="clear"></div>
    </footer>
  )
}

export default Footer
