import React from 'react'
import HeaderRu from './HeaderRu'
import FooterRu from './FooterRu'
import './layout.css'

const layout = ({ children }) => {
  return (
    <div className="gridContainer">
      <HeaderRu />
      <div className="area1">
        <main>{children}</main>
      </div>
      <FooterRu />
    </div>
  )
}

export default layout
