import React from 'react'
import { Link } from 'gatsby'

const HeaderRu = (props) => {
  return (
    <>
      <div className="right"><Link to="/">English</Link></div>
      <div id="name">
        <Link to="/ru/">InBeautyStyle</Link>
      </div>
      <div className="navLink"><Link to="/ru/">Главная</Link></div>
      <div className="navLink"><Link to="/ru/prices">Цены</Link></div>
      <div className="navLink"><Link to="/ru/s/bdr">BDR</Link></div>
      <div className="attn1">
        запись на процедуры: 647-988-8989
      </div>
      <div className="clear" />
    </>
  )
}

export default HeaderRu
